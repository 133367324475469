import React from "react"
import Layout from "../components/layout/layout"
import TableOfContents from "../components/homepage/table-of-contents"
import SEO from "../components/layout/seo"
import { useEffect } from "react"

export default function Homepage({ data }) {
  const seo = {
    title: "Klinika Chirurgii Plastycznej | Operacje plastyczne Szczecin",
    description: "Centrum chirurgi plastycznej w Szczecinie - oddaj się w ręce specjalisty! Dr Maciej Pastucha jest chirurgiem plastykiem z ponad 20 letnim doświadczeniem.",
    url: "https://mpastucha.pl/"
  }

  useEffect(() => {
    let interval = setInterval(() => {
      if (typeof window !== "undefined" && window.HomeFunctions && window.$) {
        clearInterval(interval);
        window.HomeFunctions("VNZwrzzZeOs");
      }
    }, 50)
  });

  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        url={seo.url}
      />
      <TableOfContents />
      <section className="fullheight hero-bg-dr cover">
        <div className="hero-cnt">
          <div className="txt-title txt-white">
            <h1><strong>Chirurgia plastyczna w Szczecinie – Dr n. med. Maciej
              Pastucha</strong></h1>
            <p><span className="h2" style={{ color: "white" }}>Dr Maciej Pastucha jest chirurgiem plastykiem z ponad 30 letnim doświadczeniem</span>
            </p>
            <TableOfContents />
          </div>
          <div className="btn-list smplus-only ">
            <div className="btn btn-contrast">30 lat w profesji</div>
            <div className="btn btn-contrast">własna praktyka</div>
            <div className="btn btn-contrast">ponad 4000 operacji</div>
          </div>
          <div className="txt-white txt-light smplus-only">
            <a href="/o-mnie/" className="btn-link-arrow btn-link-arrow-r">przeczytaj życiorys <span className="ico-arrow" /></a>
          </div>
        </div>
        <div className="hero-bot">
          <div className="container-fluid pad-l-big pad-r-big">
            <div className="row ">
              <div className="col-4sm">
                <div className="hero-highlighted txt-white txt-al-l mdplus-only"><p>Popularne <br /> zabiegi</p> <a
                  href="/zabiegi/powiekszanie-piersi-okragle/" className="h3 txt-white">Powiększanie
                  piersi (implanty okrągłe Mentor)<span className="ico-arrow" /></a></div>
              </div>
              <div className="col-4sm">
                <div className="btn-list"><a href="/kontakt/"
                                             className="btn btn-contrast btn-contrast-wide">zarejestruj się online</a>
                </div>
              </div>
              <div className="col-4sm">
                <div className="hero-award white txt-al-r mdplus-only txt-small">
                  <div className="hero-award-logo"><img
                    src="/uploads/2015/07/twojstyl.png" alt="twoj styl logo" /></div>
                  <p>nagrodzony przez:</p></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="fullheight hero-bg-seo cover carousel-home">
        <div className="item">
          <div className="carcont">
            <div className="txt-title txt-white txt-cnt">
              <h2>Klinika chirurgii plastycznej Dr. Macieja Pastuchy</h2>
            </div>
            <div className="txt-white txt-cnt">
              <p>Centrum Chirurgii Plastycznej Dr. Macieja Pastuchy to wyjątkowe miejsce na mapie Szczecina. To jedyna
                takie miejsce, gdzie na najwyższej jakości, innowacyjnym i profesjonalnym sprzęcie pracują wysokiej
                klasy specjaliści. Oferujemy pełną gamę usług z zakresu medycyny estetycznej i chirurgii plastycznej. W
                naszym centrum istnieje możliwość wykonania zabiegów udoskonalających i poprawiających wygląd twarzy i
                ciała. Naszym celem nadrzędnym jest dobro i zadowolenie każdego Pacjenta. Stosujemy maksymalnie
                zindywidualizowane podejście do Pacjentów – jego potrzeb, wymagań, oczekiwań. Pomagamy odzyskać normalne
                życie, umocnić pewność siebie, pozbyć się kompleksów i spełnić marzenia</p>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="carcont">
            <div className="txt-title txt-white txt-cnt">
              <h2>Dr n. med. Maciej Pastucha</h2>
            </div>
            <div className="txt-white txt-cnt">
              <p>Chirurg plastyczny ze Szczecina, najwyższej klasy specjalista, posiadający ponad 30-letnie
                doświadczenie w zawodzie, cieszący się ogromnym zaufaniem i uznaniem wśród Pacjentów. To lekarz, który
                posiada wyjątkowe umiejętności, przyswojone w trakcie staży i kursów w polskich oraz zagranicznych
                klinikach, co daje Pacjentom gwarancję bezpieczeństwa, a także satysfakcję z przeprowadzanych zabiegów.
                Chirurgia plastyczna to dla dr Macieja Pastuchy nie tylko zawód, ale przede wszystkim ogromna miłość i
                pasja. Praktyczne doświadczenie w chirurgii estetycznej Dr Macieja Pastuchy w połączeniu z rzetelną
                wiedzą teoretyczną zdobytą podczas kursów medycznych w Polsce i na świecie, zapewnia uzyskanie
                oczekiwanych rezultatów operacyjnych.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="nofullheight hero-bg-proc hero-proc cover">
        <div className="hero-top">
          <h2 className="txt-title">Oferujemy <strong>ponad 30</strong> różnych zabiegów.</h2>
          <div className="btn-list"><a href="/zabiegi/korekcja-nosa-i-przegrody/" className="btn btn-lighter"><span
            className="ico-arrow" />Twarz</a> <a href="/zabiegi/powiekszanie-piersi-okragle/"
                                                 className="btn btn-lighter"><span className="ico-arrow" />Tułów</a> <a
            href="/zabiegi/lifting-ramion/" className="btn btn-lighter"><span className="ico-arrow" />Kończyny</a> <a
            href="/zabiegi/redukcja-warg-sromowych-mniejszych/" className="btn btn-lighter"><span
            className="ico-arrow" />Inne</a></div>
          <p>Wybierz część ciała, aby poznać szczegóły.</p>
        </div>
        <div className="hero-bot">
          <div className="container-fluid pad-l-big pad-r-big md-ov-h">
            <div className="row">
              <div className="col-3md">
                <div className="hero-proc-opinions txt-white txt-al-l">
                  <a href="/opinie/" className="h1 txt-contrast"><span className="ico-heart" />Listy od naszych
                    pacjentek</a>
                  <p>Kliknij, aby przeczytać opinie.</p>
                </div>
              </div>
              <div className="col-8lg-9md mdplus-only">
                <div className="row nogutter">
                  <div className="col-6md ">
                    <a href="/opinie/">
                      <div className="opinion">
                        <h4>Powiększanie piersi (implanty anatomiczne Mentor)</h4>
                        <p>Witam wszystkich. Pół roku temu miałam operacje powiększania biustu u doktora Pastuchy. Muszę
                          napisać szczerze, że jest najlepszym chirurgiem plastycz...</p>
                      </div>
                      <div className="txt-al-r txt-small opinion-person">
                        <p>~Aneta ze Szczecina</p>
                      </div>
                    </a>
                  </div>
                  <div className="col-6md ">
                    <a href="/opinie/">
                      <div className="opinion">
                        <h4>Korekta nosa</h4>
                        <p>Chciałabym serdecznie podziękować Panu doktorowi za nowy, piękny nos. Pan doktor to
                          chirurg-specjalista, jakiego życzyłabym każdemu pacjentowi. Oprócz...</p>
                      </div>
                      <div className="txt-al-r txt-small opinion-person">
                        <p>~Anna</p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="nofullheight hero-bg-vid cover">
        <div id="player1-wrp" className="embed-responsive hide">
          <div id="player" />
        </div>
        <div id="button-vid-home" className="hero-cnt hero-cnt-vid">
          <div className="btn-list">
            <button className="btn btn-contrast btn-big btn-vid"><span className="ico-play" /> Obejrzyj film o naszym
              centrum
            </button>
          </div>
        </div>
      </section>

    </Layout>
  )
}
